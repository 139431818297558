import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Contact Us"
        description="To contact Boice Bros. Ice Cream, email Contact@boicebrosicecream.com or call us at 845-340-2018 "
      />
      <div className="page contact"><div className="inner">

        <p>Boice Bros. Ice Cream is located at:<br/>
62 O’Neil Street, Kingston, NY 12401</p>

<p><a href="tel:8453402018">845-340-2018</a></p>

<br />
<br />

        <div className="mapcontainer"><iframe width="100%" height="500" src="https://maps.google.com/maps?q=62%20O%E2%80%99Neil%20Street%2C%20Kingston%2C%20NY%2012401&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div>
      </div></div>
    </Layout>
  )



}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
